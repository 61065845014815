<template>
  <div class="credit-summary">
    <v-card class="card-border elevation-1">
      <v-row class="justify-space-between" no-gutters>
        <div class="mt-7 ml-8">
          <span class="subtitle-1 aon-gray-02--text">Credit Rating</span>
          <div class="mt-1">
            <i
              :class="`fal fa-circle aon-${ratingColor(creditRating)}-text`"
            ></i>
            <span class="ml-1 rating-value aon-peacock--text font-weight-light">
              {{ $t(`cpiApplication.app.creditRatings.${creditRating}`) }}
            </span>
          </div>
        </div>
        <v-btn
          v-if="!applicationIsFinalized"
          text
          link
          class="mt-7 mr-8 aon-teal--text"
          @click="$emit('showCreditQuestions')"
          >{{ $t('cpiApplication.viewCreditResponses') }}</v-btn
        >
      </v-row>
      <div class="p bold mt-8 mx-12">
        {{ $t('cpiApplication.app.businessStrategy.label') }}
      </div>
      <div class="mx-12 mt-1">{{ this.$v.fields.businessStrategy.$model }}</div>

      <div class="p bold mt-8 mx-12">
        {{ $t('cpiApplication.app.additionalDetails.label') }}
      </div>
      <div class="mx-12 mt-1">
        {{ this.$v.fields.additionalDetails.$model }}
      </div>

      <div class="p bold mt-8 mx-12">
        {{ $t('cpiApplication.app.nonPatentIp.label') }}
      </div>
      <div class="mx-12 mt-1">{{ this.$v.fields.nonPatentIp.$model }}</div>

      <div class="p bold mt-8 mx-12">
        {{ $t('cpiApplication.app.topCompetitors.label') }}
      </div>
      <div class="mx-12 mt-1 mb-8">
        {{ this.$v.fields.topCompetitors.$model }}
      </div>
      <v-divider class="mx-4" />
      <LoanTermsMatrix class="mx-6 mt-10 mb-8" :form="creditForm" read-only />
    </v-card>
  </div>
</template>

<script>
import LoanTermsMatrix from '@/components/CpiLending/LoanTermsMatrix';

export default {
  name: 'CreditSummary',
  components: {
    LoanTermsMatrix,
  },
  props: {
    creditRating: {
      type: String,
      required: true,
    },
    creditForm: {
      type: Object,
      required: true,
    },
    applicationIsFinalized: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    $v() {
      return this.creditForm.$v;
    },
  },
  methods: {
    ratingColor(rating) {
      switch (rating) {
        case 'EXCELLENT':
          return 'success';
        case 'ADEQUATE':
          return 'warning';
        case 'POOR':
          return 'error';
      }
    },
  },
};
</script>

<style lang="scss">
.credit-summary {
  .card-height-temp {
    min-height: 70rem;
  }
  .rating-value {
    font-size: larger;
  }
  i {
    font-size: x-small;
  }
  .card-border {
    border: $border-gray-05;
    border-radius: $sheet-border-radius;
    min-height: 40rem;
  }
}
</style>
