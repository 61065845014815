<template>
  <v-row class="cpi-application px-0 pt-0" no-gutters>
    <Spinner v-if="loading" class="mt-4 mx-auto" />
    <template v-else>
      <div class="application-detail">
        <Alert
          v-if="cpiApplication.deleted"
          banner
          full-width
          persistent
          type="info"
        >
          {{ $t('cpiApplication.discardedBanner') }}
        </Alert>

        <div class="cpi-header general-information">
          <v-row
            dense
            class="px-9 pt-4 h3 aon-peacock--text justify-space-between title-row"
          >
            <v-col cols="auto">
              <v-row dense class="pt-2">
                <h5>
                  {{ $t('cpiApplication.title') }}
                </h5>
              </v-row>
              <v-row dense>
                <p class="p small aon-gray-02--text">
                  {{ applicationStartedBy }}
                </p>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row dense>
                <v-col
                  v-if="cpiApplication.applicantName"
                  cols="auto"
                  class="mr-10"
                >
                  <div class="p small aon-gray-02--text pt-2">
                    {{ $t('cpiApplication.company') }}
                  </div>
                  <p class="p aon-gray-01--text">
                    {{ cpiApplication.applicantName }}
                  </p>
                </v-col>
                <v-col v-if="cpiApplication.businessLocation" cols="auto">
                  <div class="p small aon-gray-02--text pt-2">
                    {{ $t('cpiApplication.headquarters') }}
                  </div>
                  <p class="p aon-gray-01--text">{{ companyLocationText }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="px-4 general-information">
          <v-tabs
            v-if="!applicationIsFinalized"
            v-model="selectedTab"
            slider-size="4"
          >
            <v-tab
              v-for="[index, tab] of applicationTabs.entries()"
              :key="`selectedTab_${tab}`"
              :disabled="!sectionEnabled(tab)"
            >
              <span class="mr-2 tab-index-number" :class="tabIndexClass[index]">
                <i
                  v-if="tabIndexClass[index] === 'completed-tab'"
                  class="fal fa-check"
                ></i>
                <div v-else>{{ index + 1 }}</div>
              </span>
              <span>{{ $t(`cpiApplication.${tab}.tab`) }}</span>
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items :key="`selectedTab_${selectedTab}`" v-model="selectedTab">
          <v-tab-item>
            <SectionBanner section="app">
              <template slot="header-buttons">
                <AppButton
                  v-if="
                    !isNewApplication &&
                      cpiApplication.status === 'DRAFT' &&
                      !cpiApplication.deleted
                  "
                  class="mr-2"
                  :disabled="saving !== null"
                  :loading="saving === 'discard'"
                  variation="ghost"
                  @click="discardApplication"
                >
                  {{ $t('cpiApplication.app.actions.discard') }}
                </AppButton>
                <AppButton
                  v-if="
                    (cpiApplication.status === 'DRAFT' || isNewApplication) &&
                      !cpiApplication.deleted
                  "
                  :disabled="saving !== null"
                  :loading="!!saving"
                  @click="submitCreditApplication()"
                >
                  {{ $t('cpiApplication.app.actions.submit') }}
                </AppButton>
              </template>
            </SectionBanner>
            <Application
              :credit-form="creditForm"
              :location-form="locationForm"
              :read-only="!applicationEnabled"
              :saving="saving"
              class="mx-11"
              @saveApplication="saveApplication"
            />
          </v-tab-item>
          <v-tab-item>
            <SectionBanner section="ip">
              <template slot="header-buttons">
                <AppButton
                  v-if="
                    cpiApplication.status === 'IP_REVIEW' &&
                      !cpiApplication.deleted
                  "
                  :loading="!!saving"
                  @click="submitIpAnalysis()"
                >
                  {{ $t('cpiApplication.ip.tab') }}
                </AppButton>
              </template>
            </SectionBanner>
            <div class="mx-10 my-8">
              <v-row>
                <v-col cols="5">
                  <CreditSummary
                    :credit-rating="cpiApplication.creditRating"
                    :credit-form="creditForm"
                    @showCreditQuestions="changeTab(0)"
                  />
                </v-col>
                <v-col cols="7">
                  <IpAnalysis
                    ref="ipAnalysis"
                    :form="ipForm"
                    class="ip-analysis"
                    :read-only="!ipAnalysisEnabled"
                    :saving="saving"
                    @saveApplication="saveApplication"
                  />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <SectionBanner section="finalAnalysis">
              <template slot="header-buttons">
                <AppButton
                  v-if="
                    cpiApplication.status === 'FINAL_ANALYSIS' &&
                      !cpiApplication.deleted
                  "
                  :loading="!!saving"
                  @click="submitFinalAnalysis()"
                >
                  {{ $t('cpiApplication.finalAnalysis.tab') }}
                </AppButton>
              </template>
            </SectionBanner>
            <div class="mx-10 my-8">
              <v-row>
                <v-col>
                  <FinalAnalysis
                    :form="finalAnalysisForm"
                    :saving="saving"
                    :system-recommendation="cpiApplication.systemRecommendation"
                    :read-only="!finalAnalysisEnabled"
                    @saveApplication="saveApplication"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CreditSummary
                    :credit-rating="cpiApplication.creditRating"
                    :credit-form="creditForm"
                    @showCreditQuestions="changeTab(0)"
                  />
                </v-col>
                <v-col>
                  <IpSummary
                    v-model="cpiApplication"
                    @showIpQuestions="changeTab(1)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card outlined>
                    <ApplicationHistory v-model="cpiApplication" />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <SectionBanner v-if="!applicationIsFinalized" section="decision">
              <template
                v-if="
                  cpiApplication.status !== 'APPROVED' &&
                    cpiApplication.status !== 'REJECTED'
                "
                slot="header-buttons"
              >
                <v-menu offset-y left nudge-bottom="10">
                  <template v-slot:activator="{ on, attrs }">
                    <AppButton
                      :disabled="
                        cpiApplication.status !== 'DEAL_PRIORITIZATION'
                      "
                      class="mr-2"
                      v-bind="attrs"
                      variation="ghost"
                      v-on="on"
                    >
                      {{ $t('cpiApplication.decision.actions.reject') }}
                    </AppButton>
                  </template>
                  <v-list>
                    <v-list-item dense>
                      <v-btn
                        text
                        class="aon-teal--text bold"
                        @click="finalizeApplication(3)"
                      >
                        {{ $t(`cpiApplication.decision.tiers.3.decision`) }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item dense>
                      <v-btn
                        text
                        class="aon-teal--text"
                        @click="finalizeApplication(4)"
                      >
                        {{ $t(`cpiApplication.decision.tiers.4.decision`) }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu offset-y left nudge-bottom="10">
                  <template v-slot:activator="{ on, attrs }">
                    <AppButton
                      :disabled="
                        cpiApplication.status !== 'DEAL_PRIORITIZATION'
                      "
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('cpiApplication.decision.actions.accept') }}
                    </AppButton>
                  </template>
                  <v-list>
                    <v-list-item dense>
                      <v-btn
                        text
                        class="aon-teal--text bold"
                        @click="finalizeApplication(2)"
                      >
                        {{ $t(`cpiApplication.decision.tiers.2.decision`) }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item dense>
                      <v-btn
                        text
                        class="aon-teal--text"
                        @click="finalizeApplication(1)"
                      >
                        {{ $t(`cpiApplication.decision.tiers.1.decision`) }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </SectionBanner>
            <DealDecision
              v-model="cpiApplication"
              :deal-decision-form="finalAnalysisForm"
              :credit-form="creditForm"
              class="mx-10 mt-8"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { getUtcDateInLocal } from '@aon/cfs-utils';
import {
  Application,
  ApplicationHistory,
  CreditSummary,
  DealDecision,
  FinalAnalysis,
  IpAnalysis,
  IpSummary,
  SectionBanner,
} from '@/components/CpiLending';
import HeadquartersLocation from '@/lib/forms/company-profile/headquarters-location';
import IpAnalysisForm from '@/lib/forms/cpi-application/ip-analysis';
import CreditApplicationForm from '@/lib/forms/cpi-application/credit-application';
import FinalAnalysisForm from '@/lib/forms/cpi-application/final-analysis';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';

export default {
  name: 'CpiApplication',
  components: {
    Application,
    ApplicationHistory,
    CreditSummary,
    DealDecision,
    FinalAnalysis,
    IpAnalysis,
    IpSummary,
    SectionBanner,
  },
  mixins: [scrollToFirstError],
  data() {
    return {
      applicationTabs: ['app', 'ip', 'finalAnalysis', 'decision'],
      loading: false,
      saving: null,
      selectedTab: 0,
      creditForm: new CreditApplicationForm(),
      locationForm: HeadquartersLocation(),
      ipForm: new IpAnalysisForm(),
      finalAnalysisForm: new FinalAnalysisForm(),
    };
  },
  computed: {
    ...mapState('cpiApplications', {
      cpiApplication: state => state.cpiApplication,
    }),
    applicationIsFinalized() {
      return ['APPROVED', 'REJECTED', 'ARCHIVED'].includes(
        this.cpiApplication.status
      );
    },
    tabIndexClass() {
      if (this.applicationIsFinalized) {
        return [
          'completed-tab',
          'completed-tab',
          'completed-tab',
          'completed-tab',
        ];
      }

      const currentStatus = this.cpiApplication.status;
      if (!currentStatus || currentStatus === 'DRAFT') {
        return ['active-tab', null, null, null];
      } else if (currentStatus === 'IP_REVIEW') {
        return ['completed-tab', 'active-tab', null, null];
      } else if (currentStatus === 'FINAL_ANALYSIS') {
        return ['completed-tab', 'completed-tab', 'active-tab', null];
      } else {
        return [
          'completed-tab',
          'completed-tab',
          'completed-tab',
          'active-tab',
        ];
      }
    },
    companyLocationText() {
      return Object.entries(this.cpiApplication.businessLocation)
        .filter(
          ([key, value]) => !!value && key !== 'type' && key !== 'country'
        )
        .map(kv => kv[1])
        .join(', ');
    },
    applicationStartedBy() {
      if (
        !this.cpiApplication.appHistory ||
        this.cpiApplication.appHistory.length < 1
      ) {
        return null;
      }

      return this.$t(`cpiApplication.startedOn`, {
        user: this.cpiApplication.appHistory[0].username,
        date: getUtcDateInLocal(this.cpiApplication.appHistory[0].created),
      });
    },
    isNewApplication() {
      return this.applicationId.toLowerCase() === 'new-application';
    },
    applicationEnabled() {
      const { status, deleted } = this.cpiApplication;
      return (status === 'DRAFT' || this.isNewApplication) && !deleted;
    },
    ipAnalysisEnabled() {
      const { status, deleted } = this.cpiApplication;
      return status === 'IP_REVIEW' && !deleted;
    },
    finalAnalysisEnabled() {
      const { status, deleted } = this.cpiApplication;
      return status === 'FINAL_ANALYSIS' && !deleted;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    if (this.isNewApplication) {
      this.$store.commit('cpiApplications/setCpiApplication', this.application);
      return;
    }

    this.loading = true;
    try {
      await this.$store.dispatch(
        'cpiApplications/getCpiApplication',
        this.applicationId
      );
    } catch (error) {
      this.handleGenericApiError();
    } finally {
      this.loading = false;
    }
    this.locationForm.updateInitialValues(this.cpiApplication.businessLocation);
    this.ipForm.updateInitialValues(this.cpiApplication.ipQuestions);
    this.finalAnalysisForm.updateInitialValues(
      this.cpiApplication.finalAnalysis
    );
    this.creditForm.updateInitialValues({
      ...this.cpiApplication,
      ...this.cpiApplication.creditQuestions,
      ...this.cpiApplication.loanTerms,
    });
    this.calculateSelectedTab();
  },
  beforeCreate() {
    this.$AppEventBus.$on('cpiTabChange', tab => {
      this.changeTab(tab);
    });
  },
  methods: {
    calculateSelectedTab() {
      const tab = {
        ['DRAFT']: 0,
        ['IP_REVIEW']: 1,
        ['FINAL_ANALYSIS']: 2,
        ['DEAL_PRIORITIZATION']: 3,
        ['REJECTED']: 3,
        ['APPROVED']: 3,
        ['ARCHIVED']: 3,
      }[this.cpiApplication.status];

      this.selectedTab = tab || 0;
    },
    async discardApplication() {
      this.saving = 'discard';

      try {
        await this.$store.dispatch(
          'cpiApplications/discardApplication',
          this.applicationId
        );
      } catch (error) {
        this.handleGenericApiError();
      } finally {
        this.saving = null;
      }
    },
    handleGenericApiError() {
      this.$AppEventBus.$emit('snacktime', {
        type: 'error',
        message: this.$t('errors.unknown'),
      });
    },
    async submitCreditApplication() {
      this.creditForm.$v.$touch();
      this.locationForm.$v.$touch();

      if (this.creditForm.$v.$invalid || this.locationForm.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      await this.saveApplication();
      await this.submitApplication();
    },
    async submitIpAnalysis() {
      this.ipForm.$v.$touch();
      if (this.ipForm.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      await this.saveApplication();
      await this.submitApplication();
    },
    async submitFinalAnalysis() {
      this.finalAnalysisForm.$v.$touch();
      if (this.finalAnalysisForm.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      await this.saveApplication();
      await this.submitApplication();
    },
    async saveApplication() {
      this.saving = 'save';
      const payload = {
        ...this.creditForm.getBaseApplicationValues(),
        creditQuestions: this.creditForm.getCreditQuestionValues(),
        ipQuestions: this.ipForm.getFormValues(),
        loanTerms: this.creditForm.getLoanTermMatrixValues(),
        businessLocation: this.locationForm.getFormValues(),
        finalAnalysis: this.finalAnalysisForm.getFormValues(),
      };

      try {
        await this.$store.dispatch(
          'cpiApplications/saveCpiApplication',
          payload
        );

        if (this.isNewApplication) {
          await this.$router.replace({
            params: { applicationId: this.cpiApplication.id },
          });
        }
      } catch (error) {
        this.handleGenericApiError();
      } finally {
        this.saving = null;
      }
    },
    async finalizeApplication(tier) {
      this.saving = 'save';
      const status = tier === 1 || tier === 2 ? 'APPROVED' : 'REJECTED';
      try {
        await this.$store.dispatch('cpiApplications/finalizeApplication', {
          applicationId: this.applicationId,
          status,
          tier,
        });
      } catch (error) {
        this.handleGenericApiError();
      } finally {
        this.saving = null;
        await this.$router.push('/cpi-applications');
      }
    },
    sectionEnabled: function(section) {
      if (this.applicationIsFinalized) {
        return true;
      }
      return {
        ['app']: true,
        ['ip']: ['IP_REVIEW', 'FINAL_ANALYSIS', 'DEAL_PRIORITIZATION'].includes(
          this.cpiApplication.status
        ),
        ['finalAnalysis']: ['FINAL_ANALYSIS', 'DEAL_PRIORITIZATION'].includes(
          this.cpiApplication.status
        ),
        ['decision']: this.cpiApplication.status === 'DEAL_PRIORITIZATION',
      }[section];
    },
    async submitApplication() {
      this.saving = 'submit';
      try {
        await this.$store.dispatch(
          'cpiApplications/submitApplication',
          this.applicationId
        );
        this.calculateSelectedTab();
      } catch (error) {
        this.handleGenericApiError();
      } finally {
        this.saving = null;
      }
    },
    changeTab(tab) {
      this.selectedTab = tab;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.cpi-application {
  min-width: 100% !important;
  .v-tab {
    font-size: 14px !important;
  }

  .v-tab--disabled {
    color: $aon-gray-02;
  }
  .v-tab:not(.v-tab--active) {
    color: $aon-gray-01 !important;
    opacity: 1;
  }

  .ip-analysis {
    min-width: 47rem;
  }

  .tab-index-number {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    padding-top: 3px;
    color: $white;
    background-color: $aon-gray-02;
  }

  .active-tab {
    background-color: $aon-teal;
  }

  .completed-tab {
    background-color: $success;
  }

  .cpi-header {
    min-height: 90px;
  }

  .general-information {
    border-bottom: $border-gray-05;
    background: $white;
  }

  .application-detail {
    width: 100%;
  }

  .header {
    border-radius: $sheet-border-radius;
    overflow: hidden;
  }

  .visible-tabs {
    overflow: visible;
  }

  .title-row {
    min-height: 5rem;
  }
  .card-border {
    border: $border-gray-05;
    border-radius: $sheet-border-radius;
  }
}
</style>
