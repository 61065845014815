import { Model, VueForm } from '@aon/cfs-forms';
import { maxLength, required } from 'vuelidate/lib/validators';

const finalAnalysisModel = new Model({
  analysisRecommendation: {
    default: null,
    validations: {
      required,
    },
  },
  overallAnalysis: {
    default: null,
    validations: {
      required,
      maxLength: maxLength(1000),
    },
  },
});

export default function FinalAnalysisForm() {
  return VueForm({
    fields: finalAnalysisModel.getFields(),
    validations: finalAnalysisModel.getValidations(),
    methods: {
      getFormValues() {
        return this._getFormValues();
      },
      updateInitialValues(values) {
        this._updateInitialValues(values);
      },
    },
  });
}
