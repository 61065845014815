<template>
  <!-- Loan Terms Matrix-->
  <div class="loan-term-matrix">
    <v-row dense no-gutters>
      <v-col>
        <v-row class="justify-space-between mt-11" dense no-gutters>
          <span class="p bold">{{
            $t('cpiApplication.termMatrix.products')
          }}</span>
          <span class="mr-5">{{
            $t('cpiApplication.termMatrix.productNumber', { number: 1 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="mr-8 pt-2 text-right">{{
            $t('cpiApplication.termMatrix.productNumber', { number: 2 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="mr-8 pt-6 text-right">{{
            $t('cpiApplication.termMatrix.productNumber', { number: 3 })
          }}</span>
        </v-row>
      </v-col>
      <div>
        <v-row class="mb-3" no-gutters dense>
          <div class="p bold loan-term-text">
            {{ $t('cpiApplication.termMatrix.loanTerm') }}
          </div>
          <div class="ml-3 p bold loan-term-3-text">
            {{ $t('cpiApplication.termMatrix.loanTerm3') }}
          </div>
        </v-row>
        <v-row v-for="pt in [1, 2, 3]" :key="pt" dense no-gutters>
          <v-text-field
            v-model.number="$v.fields[`product${pt}Term`].$model"
            :error-messages="form.errors($v.fields[`product${pt}Term`])"
            class="loan-term-field text-right hide-number-spinner"
            dense
            type="number"
            outlined
            no-resize
            append-icon="%"
            :readonly="readOnly"
          />
          <v-text-field
            v-model.number="$v.fields[`product${pt}ThreeYearTerm`].$model"
            :error-messages="
              form.errors($v.fields[`product${pt}ThreeYearTerm`])
            "
            class="loan-term-field text-right ml-3 hide-number-spinner"
            dense
            type="number"
            outlined
            no-resize
            append-icon="%"
            :readonly="readOnly"
          />
        </v-row>
      </div>
    </v-row>
    <v-row class="mt-4" dense no-gutters>
      <v-col cols="12">
        <v-divider class="mr-8" />
      </v-col>
    </v-row>
    <v-row class="mt-6" dense no-gutters>
      <v-col>
        <v-row class="justify-space-between" dense no-gutters>
          <span class="p bold">{{
            $t('cpiApplication.termMatrix.countries')
          }}</span>
          <span class="pr-5">{{
            $t('cpiApplication.termMatrix.countryNumber', { number: 1 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="pr-8 pt-2 text-right">{{
            $t('cpiApplication.termMatrix.countryNumber', { number: 2 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="pr-8 pt-6 text-right">{{
            $t('cpiApplication.termMatrix.countryNumber', { number: 3 })
          }}</span>
        </v-row>
      </v-col>
      <v-tooltip :disabled="readOnly" right nudge-right="10" max-width="20rem">
        <template v-slot:activator="{ on, attrs }">
          <v-col v-bind="attrs" cols="auto" class="mr-11 pl-3" v-on="on">
            <v-row v-for="ct in [1, 2, 3]" :key="ct">
              <v-text-field
                v-model.number="$v.fields[`country${ct}Term`].$model"
                :error-messages="form.errors($v.fields[`country${ct}Term`])"
                class="loan-term-field text-right hide-number-spinner"
                dense
                type="number"
                outlined
                no-resize
                append-icon="%"
                :readonly="readOnly"
              />
              <v-text-field
                v-model.number="$v.fields[`country${ct}ThreeYearTerm`].$model"
                :error-messages="
                  form.errors($v.fields[`country${ct}ThreeYearTerm`])
                "
                class="loan-term-field text-right ml-3 hide-number-spinner"
                dense
                type="number"
                outlined
                no-resize
                append-icon="%"
                :readonly="readOnly"
              />
            </v-row>
          </v-col>
        </template>
        <span>{{ $t('cpiApplication.app.countryTooltip') }}</span>
      </v-tooltip>
    </v-row>
    <v-row class="mt-5" dense no-gutters>
      <v-col cols="12">
        <v-divider class="mr-8" />
      </v-col>
    </v-row>
    <v-row class="mt-6" dense no-gutters>
      <v-col>
        <v-row class="justify-space-between" dense no-gutters>
          <span class="p bold">{{
            $t('cpiApplication.termMatrix.innovations')
          }}</span>
          <span class="pr-5">{{
            $t('cpiApplication.termMatrix.innovationNumber', { number: 1 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="pr-8 pt-2 text-right">{{
            $t('cpiApplication.termMatrix.innovationNumber', { number: 2 })
          }}</span>
        </v-row>
        <v-row>
          <v-col /><span class="pr-8 pt-6 text-right">{{
            $t('cpiApplication.termMatrix.innovationNumber', { number: 3 })
          }}</span>
        </v-row>
      </v-col>
      <v-tooltip :disabled="readOnly" right nudge-right="10" max-width="20rem">
        <template v-slot:activator="{ on, attrs }" :disabled="readOnly">
          <v-col v-bind="attrs" cols="auto" class="pl-3 mr-11" v-on="on">
            <v-row v-for="it in [1, 2, 3]" :key="it">
              <v-text-field
                v-model.number="$v.fields[`innovation${it}Term`].$model"
                :error-messages="form.errors($v.fields[`innovation${it}Term`])"
                class="loan-term-field text-right hide-number-spinner"
                dense
                type="number"
                outlined
                no-resize
                append-icon="%"
                :readonly="readOnly"
              />
              <v-text-field
                v-model.number="
                  $v.fields[`innovation${it}ThreeYearTerm`].$model
                "
                :error-messages="
                  form.errors($v.fields[`innovation${it}ThreeYearTerm`])
                "
                class="loan-term-field text-right ml-3 hide-number-spinner"
                dense
                type="number"
                outlined
                no-resize
                append-icon="%"
                :readonly="readOnly"
              />
            </v-row>
          </v-col>
        </template>
        <span>{{ $t('cpiApplication.app.innovationsTooltip') }}</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LoanTermsMatrix',
  props: {
    form: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberPrefixes: ['product', 'country', 'innovation'],
      countryPrefixes: ['products', 'countries', 'innovations'],
    };
  },
  computed: {
    $v() {
      return this.form.$v;
    },
  },
};
</script>

<style lang="scss">
.loan-term-matrix {
  .v-text-field__details {
    height: 0;
    min-height: 0;
  }
  .loan-term-field {
    max-width: 7rem;
  }
  .divider-width {
    max-width: 15rem;
  }
  .loan-term-text {
    width: 7rem;
  }
  .loan-term-3-text {
    width: 9rem;
  }
}
</style>
