<template>
  <div class="ip-analysis ">
    <v-card class="elevation-1 mb-5">
      <v-row class="justify-space-between" dense no-gutters>
        <span class="ml-8 mt-8 p d-block bold ml-2 aon-peacock--text">
          {{ $t(`cpiApplication.ip.title`) }}
        </span>
        <AppButton
          v-if="!readOnly"
          class="aon-peacock--text mt-8 mr-9"
          style="border-color: $aon-peacock"
          variation="ghost"
          :loading="!!saving"
          @click="saveChanges()"
        >
          {{ $t('cpiApplication.save') }}
        </AppButton>
      </v-row>
      <v-row>
        <div class="mb-5 mt-2 px-12 analysis-text">
          <LabeledInput
            :label="$t('cpiApplication.ip.ipAnalysis.label')"
            required
          >
            <v-textarea
              v-model="$v.fields.ipAnalysis.$model"
              :error-messages="form.errors($v.fields.ipAnalysis)"
              dense
              class="mt-2"
              height="30rem"
              no-resize
              :readonly="readOnly"
              outlined
              :solo="readOnly"
              :flat="readOnly"
            />
          </LabeledInput>
        </div>
      </v-row>

      <v-row class="justify-space-around">
        <v-divider class="dividers mb-10" />
      </v-row>

      <v-row class=" mt-3 justify-end input-row" no-gutters>
        <span class="text-right label pt-1">
          {{ $t(`cpiApplication.ip.markets`) }}
        </span>
        <div class="ml-2 freeform-text-field ">
          <v-textarea
            v-model="$v.fields.markets.$model"
            :error-messages="form.errors($v.fields.markets)"
            dense
            class="mr-11"
            height="4rem"
            no-resize
            :readonly="readOnly"
            outlined
            :solo="readOnly"
            :flat="readOnly"
          />
        </div>
      </v-row>

      <v-row class=" justify-end input-row" no-gutters>
        <span class="text-right label pt-2">
          {{ $t(`cpiApplication.ip.numberOfPatents`) }}
        </span>
        <div class="ml-2 freeform-text-field ">
          <v-textarea
            v-model.number="$v.fields.patents.$model"
            :error-messages="form.errors($v.fields.patents)"
            dense
            class="mr-11 hide-number-spinner"
            height="2rem"
            type="number"
            no-resize
            :readonly="readOnly"
            outlined
            :solo="readOnly"
            :flat="readOnly"
            :placeholder="$t(`cpiApplication.ip.ex12`)"
          />
        </div>
      </v-row>

      <v-row class="justify-space-around">
        <v-divider class="dividers mb-10" />
      </v-row>

      <v-row
        v-for="{ question, freeText } in ipQuestions"
        :key="question"
        dense
        class="justify-end"
        no-gutters
      >
        <div class="text-right pt-3 label question-label">
          {{ $t(`cpiApplication.ip.questions.${question}.label`) }}
        </div>
        <div class="ml-2">
          <v-text-field
            v-model="$v.fields[question].$model"
            :error-messages="form.errors($v.fields[question])"
            class="number-text-field hide-number-spinner"
            dense
            outlined
            type="number"
            :solo="readOnly"
            :flat="readOnly"
            :readonly="readOnly"
            :placeholder="$t(`cpiApplication.ip.0to1`)"
          />
        </div>
        <div class="ml-2 mr-8">
          <v-text-field
            v-model="$v.fields[freeText].$model"
            :error-messages="form.errors($v.fields[freeText])"
            class=" mr-4"
            dense
            no-resize
            outlined
            :solo="readOnly"
            :flat="readOnly"
            :readonly="readOnly"
            :placeholder="$t(`cpiApplication.ip.addANote`)"
          />
        </div>
      </v-row>
      <v-row class="justify-space-around">
        <v-divider class="dividers mb-10" />
      </v-row>
      <v-row class="justify-end" no-gutters dense>
        <span class="pt-2 pr-2">
          {{ $t('cpiApplication.ip.questions.isPoor.label') }}
        </span>
        <v-checkbox
          v-model="$v.fields.isPoor.$model"
          dense
          :readonly="readOnly"
        />
        <v-col cols="5" />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';

export default {
  name: 'IpAnalysis',
  components: {
    LabeledInput,
  },
  mixins: [scrollToFirstError],
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: String,
      default: null,
    },
  },
  computed: {
    $v() {
      return this.form.$v;
    },
    ipQuestions() {
      return this.form.decimalQuestions.map((value, index) => {
        return {
          question: value,
          freeText: this.form.freeTextQuestions[index],
        };
      });
    },
  },
  methods: {
    async saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$emit('saveApplication');
    },
  },
};
</script>

<style lang="scss">
.ip-analysis {
  .input-row {
    width: 100%;
  }
  .v-card {
    border: $border-gray-05;
    border-radius: $sheet-border-radius;
  }
  .markets-patents-text {
    width: 18rem;
  }
  .analysis-text {
    width: 100%;
  }
  .dividers {
    max-width: 85%;
  }
  .question-label {
    width: 16rem;
  }
  .number-text-field {
    width: 5rem;
  }
  .freeform-text-field {
    width: 40%;
  }

  .v-input__slot fieldset {
    border: 1px solid black;
  }
  .v-input input::placeholder {
    color: $aon-gray-04;
    opacity: 1;
  }
}
</style>
