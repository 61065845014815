<template>
  <div class="py-5 pl-8">
    <div class="p bold my-2">
      {{ $t('cpiApplication.applicationHistory') }}
    </div>
    <div
      v-for="item in value.appHistory"
      :key="item + item.username + item.created"
      class="my-4 aon-gray-02--text"
    >
      {{ formatHistoryItem(item) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationHistory',
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    formatHistoryItem(item) {
      return this.$t(`cpiApplication.history.${item.status}`, {
        username: item.username,
        created: this.$options.filters.longDate(item.created),
      });
    },
  },
};
</script>
