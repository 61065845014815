import { Model, VueForm } from '@aon/cfs-forms';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
const businessLocationModel = new Model(
  {
    type: {
      default: 'CORPORATE',
      validations: { required },
    },
    country: {
      default: null,
      validations: { required, maxLength: maxLength(3) },
    },
    addressLine1: {
      default: null,
      validations: { required, maxLength: maxLength(100) },
    },
    addressLine2: {
      default: null,
      validations: { maxLength: maxLength(100) },
    },
    addressLine3: {
      default: null,
      validations: { maxLength: maxLength(100) },
    },
    locality: {
      default: null,
      validations: { required, maxLength: maxLength(100) },
    },
    county: {
      default: null,
      validations: { maxLength: maxLength(100) },
    },
    region: {
      default: null,
      validations: { required, maxLength: maxLength(100) },
    },
    postalCode: {
      default: null,
      validations: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(10),
      },
    },
  },
  { removeIfEmpty: true }
);

export default function LocationForm(initialValues) {
  return VueForm({
    fields: businessLocationModel.getFields(),
    validations: businessLocationModel.getValidations(),
    initialValues,
    methods: {
      emptyForm() {
        return Object.values(this.fields).every(
          field => !field || field === 'CORPORATE'
        );
      },
    },
  });
}
