<template>
  <div class="application">
    <v-card class="px-8 mt-8 mb-10 pb-10 card-border elevation-1">
      <v-row class="justify-space-between" dense no-gutters>
        <h5 class="mt-8 aon-peacock--text">
          {{ $t(`cpiApplication.app.title`) }}
        </h5>
        <AppButton
          v-if="!readOnly"
          class="aon-peacock--text mt-6"
          style="border-color: $aon-peacock"
          variation="ghost"
          :loading="!!saving"
          @click="saveChanges()"
        >
          {{ $t('cpiApplication.save') }}
        </AppButton>
      </v-row>
      <v-divider class="mb-6 mt-6" />
      <v-row no-gutters>
        <v-col cols="5">
          <span class="pt-2 p bold">{{
            $t('cpiApplication.app.applicantName.label')
          }}</span>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="$v.fields.applicantName.$model"
            :error-messages="creditForm.errors($v.fields.applicantName)"
            dense
            outlined
            :readonly="readOnly"
          />
        </v-col>
        <v-col cols="auto" />
      </v-row>
      <v-row>
        <v-col cols="5" />
        <v-col cols="7" class="mb-5 pl-0">
          <div class="text-right">
            <Location
              v-if="!readOnly"
              :initial-values="locationForm.fields"
              :form-label="
                locationForm.$v.$invalid ? 'Add Location' : 'Edit Location'
              "
              @submit="addUpdateLocation($event)"
            />
          </div>
          <div
            class="location-display mt-6 pt-3 text-center"
            :class="
              locationForm.$v.$invalid && locationForm.$v.$dirty
                ? 'location-error error--text'
                : null
            "
          >
            <span v-if="locationForm.$v.$invalid" class="p small">{{
              $t('cpiApplication.app.addLocation')
            }}</span>
            <span v-else>{{ companyLocationText }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="question in creditForm.baseApplicationQuestions"
        :key="question"
        no-gutters
      >
        <v-col cols="5">
          <span class="pt-2 pr-6 p bold">{{
            $t(`cpiApplication.app.${question}.label`)
          }}</span>
        </v-col>
        <v-col cols="7">
          <div class="application-text-fields"></div>
          <v-textarea
            v-model="$v.fields[question].$model"
            :error-messages="creditForm.errors($v.fields[question])"
            dense
            height="6rem"
            :readonly="readOnly"
            no-resize
            outlined
          />
        </v-col>
      </v-row>
      <v-divider class="mb-6 mt-2" />
      <v-col cols="7">
        <h5 class="mb-10 mt-8">{{ $t('cpiApplication.termMatrix.title') }}</h5>
        <LoanTermsMatrix :form="creditForm" :read-only="readOnly" />
      </v-col>
      <v-divider class="mb-6 mt-6" />
      <v-row
        v-for="question in creditForm.creditQuestions"
        :key="question"
        dense
        no-gutters
      >
        <v-col cols="5" class="pt-5 pr-6">
          <span>{{ $t(`cpiApplication.app.credit.${question}.label`) }}</span>
        </v-col>
        <v-radio-group
          v-model="$v.fields[question].$model"
          :error-messages="creditForm.errors($v.fields[question])"
          :readonly="readOnly"
          row
        >
          <v-radio color="aon-teal" label="Yes" value="YES" />
          <v-radio color="aon-teal" label="No" value="NO" />
        </v-radio-group>
        <v-col cols="auto" />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LoanTermsMatrix from '@/components/CpiLending/LoanTermsMatrix';
import Location from '@aon/cfs-company/src/components/Forms/CompanyProfile/Locations/Location';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';

export default {
  name: 'Application',
  components: {
    LoanTermsMatrix,
    Location,
  },
  mixins: [scrollToFirstError],
  props: {
    creditForm: {
      type: Object,
      required: true,
    },
    locationForm: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        characterLimit: [v => !v || v.length <= 1000 || 'Max characters 1000'],
      },
    };
  },
  computed: {
    $v() {
      return this.creditForm.$v;
    },
    companyLocationText() {
      return Object.entries(this.locationForm.getFormValues())
        .filter(
          ([key, value]) => !!value && key !== 'type' && key !== 'country'
        )
        .map(kv => kv[1])
        .join(', ');
    },
  },
  methods: {
    addUpdateLocation(values) {
      this.locationForm.$v.fields.$model = values;
    },
    async saveChanges() {
      this.$v.$touch();
      this.locationForm.$v.$touch();

      if (this.$v.$invalid || this.locationForm.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$emit('saveApplication');
    },
  },
};
</script>

<style lang="scss">
.application {
  .hide-label {
    label > span:first-child {
      display: none;
    }
  }
  .application-text-fields {
    max-height: 6rem;
  }
  .location-display {
    border: $border-gray-05;
    height: 3rem;
    color: $aon-gray-02;
    overflow-y: auto;
  }
  .location-error {
    border: 1px solid $error;
  }
}
</style>
