<template>
  <div class="ip-summary">
    <v-card class="card-border elevation-1">
      <v-row class="justify-space-between" no-gutters>
        <div class="mt-7 ml-8">
          <span class="subtitle-1 aon-gray-02--text">
            {{ $t(`cpiApplication.ip.ipRating.label`) }}
          </span>
          <div class="mt-1">
            <i
              :class="`fal fa-circle aon-${ratingColor(value.ipRating)}-text`"
            ></i>
            <span class="ml-1 rating-value aon-peacock--text font-weight-light">
              {{ $t(`cpiApplication.ip.ipRatings.${value.ipRating}`) }}
            </span>
          </div>
        </div>
        <v-btn
          v-if="!applicationIsFinalized"
          text
          link
          class="mt-7 mr-8 aon-teal--text"
          @click="$emit('showIpQuestions')"
        >
          {{ $t('cpiApplication.viewIpResponses') }}
        </v-btn>
      </v-row>
      <div class="p bold mt-8 mx-12">
        {{ $t('cpiApplication.ip.ipAnalysis.label') }}
      </div>
      <div class="mx-12 mt-1">{{ value.ipQuestions.ipAnalysis }}</div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'IpSummary',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    applicationIsFinalized() {
      return this.value.tier !== null;
    },
  },
  methods: {
    ratingColor(rating) {
      switch (rating) {
        case 'EXCELLENT':
          return 'success';
        case 'ADEQUATE':
          return 'warning';
        case 'POOR':
          return 'error';
      }
    },
  },
};
</script>

<style lang="scss">
.ip-summary {
  .card-height-temp {
    min-height: 70rem;
  }
  .rating-value {
    font-size: larger;
  }
  i {
    font-size: x-small;
  }
  .card-border {
    border: $border-gray-05;
    border-radius: $sheet-border-radius;
    min-height: 40rem;
  }
}
</style>
