<template>
  <div class="final-analysis">
    <v-card class="card-border elevation-1">
      <v-row class="justify-space-between px-8" dense no-gutters>
        <h5 class="mt-8 aon-peacock--text">
          {{ $t(`cpiApplication.finalAnalysis.title`) }}
        </h5>
        <AppButton
          v-if="!readOnly"
          class="aon-peacock--text mt-6"
          style="border-color: $aon-peacock"
          variation="ghost"
          :loading="!!saving"
          @click="saveChanges()"
        >
          {{ $t('cpiApplication.save') }}
        </AppButton>
      </v-row>
      <v-row>
        <v-col class="px-12 py-8">
          <v-row :dense="!readOnly">
            <v-col :cols="readOnly ? 'auto' : '4'">
              <span class="p small aon-gray-02--text">
                {{ $t('cpiApplication.decision.systemRecommendation') }}
              </span>
              <div>
                <v-badge
                  :color="getBadgeColor(systemRecommendation)"
                  inline
                  left
                  dot
                />
                <span
                  class="aon-peacock--text font-weight-light system-recommendation"
                >
                  {{
                    $t(
                      `cpiApplication.decision.tiers.${systemRecommendation}.display`
                    )
                  }}
                </span>
              </div>
            </v-col>
            <v-col :cols="readOnly ? 'auto' : '8'">
              <span class="p small aon-gray-02--text">
                {{ $t('cpiApplication.finalAnalysis.analysisRecommendation') }}
              </span>
              <v-row v-if="!readOnly" dense>
                <v-col cols="4">
                  <v-select
                    v-model.number="$v.fields.analysisRecommendation.$model"
                    :error-messages="
                      form.errors($v.fields.analysisRecommendation)
                    "
                    :value="analysisRecommendation"
                    :items="analysisRecommendationItems"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <div v-else>
                <v-badge
                  v-if="$v.fields.analysisRecommendation.$model"
                  :color="
                    getBadgeColor($v.fields.analysisRecommendation.$model)
                  "
                  inline
                  left
                  dot
                />
                <span
                  class="aon-peacock--text font-weight-light system-recommendation"
                >
                  {{
                    $t(
                      `cpiApplication.decision.tiers.${$v.fields.analysisRecommendation.$model}.display`
                    )
                  }}
                </span>
              </div>
            </v-col>
            <v-col
              v-if="applicationIsFinalized"
              :cols="readOnly ? 'auto' : '12'"
            >
              <span class="p small aon-gray-02--text">
                {{ $t('cpiApplication.finalAnalysis.finalDecision') }}
              </span>
              <div>
                <v-badge :color="getBadgeColor(tier)" inline left dot></v-badge>
                {{
                  $t(`cpiApplication.finalAnalysis.combinedScore.tier${tier}`)
                }}
              </div>
            </v-col>
          </v-row>
          <v-row :dense="!readOnly">
            <v-col class="pb-0" :cols="readOnly ? '12' : '4'">
              <span class="label">
                {{ $t('cpiApplication.finalAnalysis.overallAnalysis') }}
              </span>
            </v-col>
            <v-col :cols="readOnly ? '12' : '8'">
              <v-textarea
                v-model="$v.fields.overallAnalysis.$model"
                :error-messages="form.errors($v.fields.overallAnalysis)"
                dense
                :readonly="readOnly"
                no-resize
                solo
                flat
                :outlined="!readOnly"
                :placeholder="
                  readOnly && !$v.fields.overallAnalysis.$model
                    ? $t('cpiApplication.finalAnalysis.na')
                    : ''
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';

export default {
  name: 'FinalAnalysis',
  mixins: [scrollToFirstError],
  props: {
    form: {
      type: Object,
      required: true,
    },
    saving: {
      type: String,
      default: null,
    },
    systemRecommendation: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    dealDecision: {
      type: Boolean,
      default: false,
    },
    tier: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      analysisRecommendationItems: [
        {
          text: this.$t(`cpiApplication.decision.tiers.1.display`),
          value: 1,
        },
        {
          text: this.$t(`cpiApplication.decision.tiers.2.display`),
          value: 2,
        },
        {
          text: this.$t(`cpiApplication.decision.tiers.3.display`),
          value: 3,
        },
        {
          text: this.$t(`cpiApplication.decision.tiers.4.display`),
          value: 4,
        },
      ],
      rules: {
        characterLimit: [v => !v || v.length <= 1000 || 'Max characters 1000'],
      },
    };
  },
  computed: {
    $v() {
      return this.form.$v;
    },
    analysisRecommendation() {
      const analysisRecommendation = this.$v.fields.analysisRecommendation
        .$model;
      if (!analysisRecommendation) {
        return null;
      }

      return this.analysisRecommendationItems.find(
        x => x.value === analysisRecommendation
      );
    },
    applicationIsFinalized() {
      return this.tier !== null;
    },
  },
  methods: {
    getBadgeColor(value) {
      switch (value) {
        case 'EXCELLENT':
        case 1:
          return 'success';
        case 'ADEQUATE':
        case 2:
          return 'warning';
        default:
          return 'error';
      }
    },
    async saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$emit('saveApplication');
    },
  },
};
</script>

<style lang="scss">
.final-analysis {
  .system-recommendation {
    font-size: larger;
  }
  .v-input input::placeholder {
    color: $aon-gray-04;
    opacity: 1;
  }
}
</style>
