<template>
  <div class="section-banner elevation-1 px-7 pt-4 pb-8">
    <v-row class="align-center justify-space-between" dense>
      <v-col cols="auto" class="mr-4 pt-3">
        <span class="subtitle-1 py-auto">
          {{ $t(`cpiApplication.${section}.sectionHeader.title`) }}
        </span>
      </v-col>
      <v-col cols="auto py-0">
        <slot name="header-buttons"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SectionBanner',
  props: {
    section: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
.section-banner {
  background: $white;
  border-bottom: $border-gray-05;
  height: 72px;
}
</style>
