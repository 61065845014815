import { Model, VueForm } from '@aon/cfs-forms';
import { translator } from '@aon/cfs-utils/lib/translation';
import {
  required,
  maxLength,
  maxValue,
  minValue,
  numeric,
} from 'vuelidate/lib/validators';
import { castStringToNumber } from '@aon/cfs-forms/lib/casts';

const additionalValidations = {};

export const decimalQuestions = [
  'geographicDistribution',
  'countriesPerFamily',
  'assigneeDistribution',
  'expiration',
  'marketAlignment',
  'velocityMarket',
  'validityVsClaim',
  'litigationPatent',
  'litigationMarket',
];
export const freeTextQuestions = [
  'geographicDistributionText',
  'countriesPerFamilyText',
  'assigneeDistributionText',
  'expirationText',
  'marketAlignmentText',
  'velocityMarketText',
  'validityVsClaimText',
  'litigationPatentText',
  'litigationMarketText',
];

decimalQuestions.forEach(
  q =>
    (additionalValidations[q] = {
      default: null,
      validations: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
        twoDecimalPlaces: value => /^(\d?\.\d{0,2}|\d?|\.\d{0,2})$/.test(value),
      },
      casts: castStringToNumber,
    })
);
freeTextQuestions.forEach(
  q =>
    (additionalValidations[q] = {
      default: null,
      validations: {
        maxLength: maxLength(100),
      },
    })
);

const ipAnalysisModel = new Model({
  ipAnalysis: {
    default: null,
    validations: {
      required,
      maxLength: maxLength(1000),
    },
  },
  markets: {
    default: null,
    validations: {
      maxLength: maxLength(500),
    },
  },
  patents: {
    default: null,
    validations: {
      numeric: numeric,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
  },
  isPoor: {
    default: false,
  },
  ...additionalValidations,
});

export default function IpAnalysisForm(initialValues) {
  return VueForm({
    initialValues,
    fields: ipAnalysisModel.getFields(),
    validations: ipAnalysisModel.getValidations(),
    data: {
      decimalQuestions: decimalQuestions,
      freeTextQuestions: freeTextQuestions,
    },
    customErrorMessages: {
      twoDecimalPlaces: translator.$t('cpiApplication.ip.twoDecimalError'),
    },
    methods: {
      getFormValues() {
        return this._getFormValues();
      },
      updateInitialValues(values) {
        this._updateInitialValues(values);
      },
    },
    casts: ipAnalysisModel.getCasts(),
  });
}
