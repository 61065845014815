<template>
  <div class="deal-decision mt-3">
    <v-row>
      <v-col>
        <FinalAnalysis
          :form="dealDecisionForm"
          :system-recommendation="value.systemRecommendation"
          read-only
          deal-decision
          :tier="value.tier"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="columnToSplitWidth">
        <CreditSummary
          :credit-rating="value.creditRating"
          :credit-form="creditForm"
          :application-is-finalized="applicationIsFinalized"
          @showCreditQuestions="changeTab(0)"
        />
      </v-col>
      <v-col :cols="columnToSplitWidth">
        <IpSummary v-model="value" @showIpQuestions="changeTab(1)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mb-8" outlined>
          <ApplicationHistory v-model="value" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  CreditSummary,
  IpSummary,
  ApplicationHistory,
  FinalAnalysis,
} from '@/components/CpiLending';

export default {
  name: 'DealDecision',
  components: {
    CreditSummary,
    ApplicationHistory,
    IpSummary,
    FinalAnalysis,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
    dealDecisionForm: {
      type: Object,
      required: true,
    },
    creditForm: {
      type: Object,
      required: true,
    },
  },
  computed: {
    applicationIsFinalized() {
      return this.tier !== null;
    },
    columnToSplitWidth() {
      return this.tier !== null ? 6 : 12;
    },
  },
  methods: {
    getBadgeColor(value) {
      switch (value) {
        case 'EXCELLENT':
        case 1:
          return 'success';
        case 'ADEQUATE':
        case 2:
          return 'warning';
        default:
          return 'error';
      }
    },
    changeTab(tab) {
      this.$AppEventBus.$emit('cpiTabChange', tab);
    },
  },
};
</script>

<style lang="scss">
.deal-decision {
  .rating-value {
    font-size: larger;
  }
  i {
    font-size: x-small;
  }
}
</style>
