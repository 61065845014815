var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"cpi-application px-0 pt-0",attrs:{"no-gutters":""}},[(_vm.loading)?_c('Spinner',{staticClass:"mt-4 mx-auto"}):[_c('div',{staticClass:"application-detail"},[(_vm.cpiApplication.deleted)?_c('Alert',{attrs:{"banner":"","full-width":"","persistent":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.discardedBanner'))+" ")]):_vm._e(),_c('div',{staticClass:"cpi-header general-information"},[_c('v-row',{staticClass:"px-9 pt-4 h3 aon-peacock--text justify-space-between title-row",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"pt-2",attrs:{"dense":""}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.title'))+" ")])]),_c('v-row',{attrs:{"dense":""}},[_c('p',{staticClass:"p small aon-gray-02--text"},[_vm._v(" "+_vm._s(_vm.applicationStartedBy)+" ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"dense":""}},[(_vm.cpiApplication.applicantName)?_c('v-col',{staticClass:"mr-10",attrs:{"cols":"auto"}},[_c('div',{staticClass:"p small aon-gray-02--text pt-2"},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.company'))+" ")]),_c('p',{staticClass:"p aon-gray-01--text"},[_vm._v(" "+_vm._s(_vm.cpiApplication.applicantName)+" ")])]):_vm._e(),(_vm.cpiApplication.businessLocation)?_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"p small aon-gray-02--text pt-2"},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.headquarters'))+" ")]),_c('p',{staticClass:"p aon-gray-01--text"},[_vm._v(_vm._s(_vm.companyLocationText))])]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"px-4 general-information"},[(!_vm.applicationIsFinalized)?_c('v-tabs',{attrs:{"slider-size":"4"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.applicationTabs.entries()),function(ref){
var index = ref[0];
var tab = ref[1];
return _c('v-tab',{key:("selectedTab_" + tab),attrs:{"disabled":!_vm.sectionEnabled(tab)}},[_c('span',{staticClass:"mr-2 tab-index-number",class:_vm.tabIndexClass[index]},[(_vm.tabIndexClass[index] === 'completed-tab')?_c('i',{staticClass:"fal fa-check"}):_c('div',[_vm._v(_vm._s(index + 1))])]),_c('span',[_vm._v(_vm._s(_vm.$t(("cpiApplication." + tab + ".tab"))))])])}),1):_vm._e()],1),_c('v-tabs-items',{key:("selectedTab_" + _vm.selectedTab),model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('SectionBanner',{attrs:{"section":"app"}},[_c('template',{slot:"header-buttons"},[(
                  !_vm.isNewApplication &&
                    _vm.cpiApplication.status === 'DRAFT' &&
                    !_vm.cpiApplication.deleted
                )?_c('AppButton',{staticClass:"mr-2",attrs:{"disabled":_vm.saving !== null,"loading":_vm.saving === 'discard',"variation":"ghost"},on:{"click":_vm.discardApplication}},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.app.actions.discard'))+" ")]):_vm._e(),(
                  (_vm.cpiApplication.status === 'DRAFT' || _vm.isNewApplication) &&
                    !_vm.cpiApplication.deleted
                )?_c('AppButton',{attrs:{"disabled":_vm.saving !== null,"loading":!!_vm.saving},on:{"click":function($event){return _vm.submitCreditApplication()}}},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.app.actions.submit'))+" ")]):_vm._e()],1)],2),_c('Application',{staticClass:"mx-11",attrs:{"credit-form":_vm.creditForm,"location-form":_vm.locationForm,"read-only":!_vm.applicationEnabled,"saving":_vm.saving},on:{"saveApplication":_vm.saveApplication}})],1),_c('v-tab-item',[_c('SectionBanner',{attrs:{"section":"ip"}},[_c('template',{slot:"header-buttons"},[(
                  _vm.cpiApplication.status === 'IP_REVIEW' &&
                    !_vm.cpiApplication.deleted
                )?_c('AppButton',{attrs:{"loading":!!_vm.saving},on:{"click":function($event){return _vm.submitIpAnalysis()}}},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.ip.tab'))+" ")]):_vm._e()],1)],2),_c('div',{staticClass:"mx-10 my-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('CreditSummary',{attrs:{"credit-rating":_vm.cpiApplication.creditRating,"credit-form":_vm.creditForm},on:{"showCreditQuestions":function($event){return _vm.changeTab(0)}}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('IpAnalysis',{ref:"ipAnalysis",staticClass:"ip-analysis",attrs:{"form":_vm.ipForm,"read-only":!_vm.ipAnalysisEnabled,"saving":_vm.saving},on:{"saveApplication":_vm.saveApplication}})],1)],1)],1)],1),_c('v-tab-item',[_c('SectionBanner',{attrs:{"section":"finalAnalysis"}},[_c('template',{slot:"header-buttons"},[(
                  _vm.cpiApplication.status === 'FINAL_ANALYSIS' &&
                    !_vm.cpiApplication.deleted
                )?_c('AppButton',{attrs:{"loading":!!_vm.saving},on:{"click":function($event){return _vm.submitFinalAnalysis()}}},[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.finalAnalysis.tab'))+" ")]):_vm._e()],1)],2),_c('div',{staticClass:"mx-10 my-8"},[_c('v-row',[_c('v-col',[_c('FinalAnalysis',{attrs:{"form":_vm.finalAnalysisForm,"saving":_vm.saving,"system-recommendation":_vm.cpiApplication.systemRecommendation,"read-only":!_vm.finalAnalysisEnabled},on:{"saveApplication":_vm.saveApplication}})],1)],1),_c('v-row',[_c('v-col',[_c('CreditSummary',{attrs:{"credit-rating":_vm.cpiApplication.creditRating,"credit-form":_vm.creditForm},on:{"showCreditQuestions":function($event){return _vm.changeTab(0)}}})],1),_c('v-col',[_c('IpSummary',{on:{"showIpQuestions":function($event){return _vm.changeTab(1)}},model:{value:(_vm.cpiApplication),callback:function ($$v) {_vm.cpiApplication=$$v},expression:"cpiApplication"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('ApplicationHistory',{model:{value:(_vm.cpiApplication),callback:function ($$v) {_vm.cpiApplication=$$v},expression:"cpiApplication"}})],1)],1)],1)],1)],1),_c('v-tab-item',[(!_vm.applicationIsFinalized)?_c('SectionBanner',{attrs:{"section":"decision"}},[(
                _vm.cpiApplication.status !== 'APPROVED' &&
                  _vm.cpiApplication.status !== 'REJECTED'
              )?_c('template',{slot:"header-buttons"},[_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":_vm.cpiApplication.status !== 'DEAL_PRIORITIZATION',"variation":"ghost"}},'AppButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.decision.actions.reject'))+" ")])]}}],null,false,3626686020)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"aon-teal--text bold",attrs:{"text":""},on:{"click":function($event){return _vm.finalizeApplication(3)}}},[_vm._v(" "+_vm._s(_vm.$t("cpiApplication.decision.tiers.3.decision"))+" ")])],1),_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"aon-teal--text",attrs:{"text":""},on:{"click":function($event){return _vm.finalizeApplication(4)}}},[_vm._v(" "+_vm._s(_vm.$t("cpiApplication.decision.tiers.4.decision"))+" ")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":_vm.cpiApplication.status !== 'DEAL_PRIORITIZATION'}},'AppButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('cpiApplication.decision.actions.accept'))+" ")])]}}],null,false,3941097195)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"aon-teal--text bold",attrs:{"text":""},on:{"click":function($event){return _vm.finalizeApplication(2)}}},[_vm._v(" "+_vm._s(_vm.$t("cpiApplication.decision.tiers.2.decision"))+" ")])],1),_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"aon-teal--text",attrs:{"text":""},on:{"click":function($event){return _vm.finalizeApplication(1)}}},[_vm._v(" "+_vm._s(_vm.$t("cpiApplication.decision.tiers.1.decision"))+" ")])],1)],1)],1)],1):_vm._e()],2):_vm._e(),_c('DealDecision',{staticClass:"mx-10 mt-8",attrs:{"deal-decision-form":_vm.finalAnalysisForm,"credit-form":_vm.creditForm},model:{value:(_vm.cpiApplication),callback:function ($$v) {_vm.cpiApplication=$$v},expression:"cpiApplication"}})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }