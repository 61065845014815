import { Model, VueForm } from '@aon/cfs-forms';
import {
  required,
  maxLength,
  maxValue,
  minValue,
  numeric,
} from 'vuelidate/lib/validators';

const additionalValidations = {};

export const baseApplicationQuestions = [
  'businessStrategy',
  'additionalDetails',
  'nonPatentIp',
  'topCompetitors',
];

export const loanTermMatrixQuestions = [
  'product1Term',
  'product2Term',
  'product3Term',
  'product1ThreeYearTerm',
  'product2ThreeYearTerm',
  'product3ThreeYearTerm',
  'country1Term',
  'country2Term',
  'country3Term',
  'country1ThreeYearTerm',
  'country2ThreeYearTerm',
  'country3ThreeYearTerm',
  'innovation1Term',
  'innovation2Term',
  'innovation3Term',
  'innovation1ThreeYearTerm',
  'innovation2ThreeYearTerm',
  'innovation3ThreeYearTerm',
];

export const creditQuestions = [
  'revenueFiveMillion',
  'positiveCashFlow',
  'revenueProjectionsSupported',
  'commercialPurposes',
  'previousFinancing',
  'lastValuationTwoTimesLoan',
  'equityRaisedTwoTimesLoan',
  'priorAcquisitionOffer',
  'ipNotEncumbered',
  'assignableLicenses',
  'ipFreeFromLegalAction',
];

baseApplicationQuestions.forEach(
  q =>
    (additionalValidations[q] = {
      default: null,
      validations: {
        maxLength: maxLength(1000),
      },
    })
);

creditQuestions.forEach(
  q =>
    (additionalValidations[q] = {
      default: null,
      validations: {
        required,
      },
    })
);

loanTermMatrixQuestions.forEach(
  q =>
    (additionalValidations[q] = {
      default: null,
      validations: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
    })
);

const creditQuestionsModel = new Model({
  applicantName: {
    default: null,
    validations: {
      required,
      maxLength: maxLength(100),
    },
  },
  ...additionalValidations,
});

export default function CreditApplicationForm() {
  return VueForm({
    fields: creditQuestionsModel.getFields(),
    validations: creditQuestionsModel.getValidations(),
    data: {
      creditQuestions: creditQuestions,
      baseApplicationQuestions,
    },
    methods: {
      getBaseApplicationValues() {
        const results = {};
        const formValues = this._getFormValues();
        baseApplicationQuestions.forEach(
          bap => (results[bap] = formValues[bap])
        );
        results['applicantName'] = formValues['applicantName'];
        return results;
      },
      getLoanTermMatrixValues() {
        const results = {};
        const formValues = this._getFormValues();
        loanTermMatrixQuestions.forEach(mq => (results[mq] = formValues[mq]));
        return results;
      },
      getCreditQuestionValues() {
        const results = {};
        const formValues = this._getFormValues();
        creditQuestions.forEach(mq => (results[mq] = formValues[mq]));
        return results;
      },
      updateInitialValues(values) {
        this._updateInitialValues(values);
      },
    },
  });
}
